import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-react-intl'

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath } = pageContext
  const intl = useIntl()

  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {previousPagePath && (
          <div className="navbar-item">
            <Link
              to={previousPagePath}
              rel="prev"
              className="button is-primary is-light"
            >
              {intl.messages.previous}
            </Link>
          </div>
        )}
        {nextPagePath && (
          <div className="navbar-item">
            <Link
              to={nextPagePath}
              rel="next"
              className="button is-primary is-light"
            >
              {intl.messages.next}
            </Link>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Pagination
