import React from 'react'
import { graphql } from 'gatsby'
import Bounce from 'react-reveal/Bounce'
import { useIntl } from 'gatsby-plugin-react-intl'
import Pagination from '../components/Pagination'

import Layout from '../components/Layout'
import GalleryComponent from '../components/gallery'

const Gallery = ({ data, pageContext }) => {
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false)
  const intl = useIntl()
  const { edges } = data.allWpMediaItem
  const photos = edges.map(({ node: image }) => {
    return {
      src: image.sourceUrl,
      alt: image.altText,
      sizes: ['(min-width: 480px) 50vw'],
      width: parseInt(image.gallery.width, 10) * 2,
      height: parseInt(image.gallery.height, 10),
      // mediaDetails: image.media_details
    }
  })

  return (
    <Layout viewerIsOpen={viewerIsOpen}>
      <section className="section-bubble1">
        <div className="container m-top">
          <Bounce left>
            <h1 className="title rainbow-underline">{intl.messages.gallery}</h1>
            <div>
              <GalleryComponent
                photos={photos}
                onViewerOpen={setViewerIsOpen}
              />
              <Pagination pageContext={pageContext} pathPrefix="/" />
            </div>
          </Bounce>
        </div>
      </section>
    </Layout>
  )
}

export default Gallery

export const pageQuery = graphql`
  query GalleryQuery($limit: Int!, $skip: Int!) {
    allWpMediaItem(
      filter: { gallery: { isgallery: { eq: true } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          gallery {
            width
            height
          }
          altText
          caption
          date
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`
